import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export const collectionFormat = (collection) => ({
  ...collection,
  cycle_start_date: collection.cycle_start_date ? moment.unix(collection.cycle_start_date) : null,
  cycle_end_date: collection.cycle_end_date ? moment.unix(collection.cycle_end_date) : null,
  enrollment_end_date: collection.enrollment_end_date ? moment.unix(collection.enrollment_end_date) : null,
  data_displays_date: collection.data_displays_date ? moment.unix(collection.data_displays_date) : null,
  isr_access_date: collection.isr_access_date ? moment.unix(collection.isr_access_date) : null,
  omr_access_date: collection.omr_access_date ? moment.unix(collection.omr_access_date) : null,
  isr_teacher_date: collection.isr_teacher_date ? moment.unix(collection.isr_teacher_date) : null,
});

export const collectionsFormat = ({ data }) => data.map((item) => collectionFormat(item));

export const formatDataCollection = (collection) => {
  delete collection.parentTenantId;
  delete collection.schoolYear;
  delete collection.parentDataCollectionId;

  return ({
    ...collection,
    cycleStartDate: collection.cycleStartDate ? moment.unix(collection.cycleStartDate) : null,
    cycleEndDate: collection.cycleEndDate ? moment.unix(collection.cycleEndDate) : null,
    enrollmentEndDate: collection.enrollmentEndDate ? moment.unix(collection.enrollmentEndDate) : null,
    dataDisplaysDate: collection.dataDisplaysDate ? moment.unix(collection.dataDisplaysDate) : null,
    isrAccessDate: collection.isrAccessDate ? moment.unix(collection.isrAccessDate) : null,
    omrAccessDate: collection.omrAccessDate ? moment.unix(collection.omrAccessDate) : null,
    isrTeacherDate: collection.isrTeacherDate ? moment.unix(collection.isrTeacherDate) : null,
  });
};

export const formatDataCollections = (dataCollections) => dataCollections.map((dc) => formatDataCollection(dc));

export const formatForRequest = (data) => {
  delete data.id;
  delete data.isOpenAfterButton;
  delete data.type;

  return ({
    ...data,
    cycleEndDate: moment(data.cycleEndDate).unix(),
    cycleStartDate: moment(data.cycleStartDate).unix(),
    dataDisplaysDate: moment(data.dataDisplaysDate).unix(),
    enrollmentEndDate: moment(data.enrollmentEndDate).unix(),
    isrAccessDate: moment(data.isrAccessDate).unix(),
    isrTeacherDate: moment(data.isrTeacherDate).unix(),
    omrAccessDate: moment(data.omrAccessDate).unix(),
  });
};

export const formatForUpdate = (data) => ({
  ...data,
  cycleEndDate: data.cycleEndDate ? moment(data.cycleEndDate).unix() : undefined,
  cycleStartDate: data.cycleStartDate ? moment(data.cycleStartDate).unix() : undefined,
  dataDisplaysDate: data.dataDisplaysDate ? moment(data.dataDisplaysDate).unix() : undefined,
  enrollmentEndDate: data.enrollmentEndDate ? moment(data.enrollmentEndDate).unix() : undefined,
  isrAccessDate: data.isrAccessDate ? moment(data.isrAccessDate).unix() : undefined,
  isrTeacherDate: data.isrTeacherDate ? moment(data.isrTeacherDate).unix() : undefined,
  omrAccessDate: data.omrAccessDate ? moment(data.omrAccessDate).unix() : undefined,
});

export const locationsFormat = ({ data }) => data.map((item) => ({
  id: item.location_id,
  name: item.location_name_label,
  uniqueId: item.location_unique_id,
  parentId: item.location_parent_id,
  tenantParentId: item.location_tenant_parent_id,
  studentEnrollmentCount: parseInt(item.student_enrollment_count, 10),
  assignmentCount: parseInt(item.data_collection_assignment_count, 10),
  assignees: `${item.data_collection_assignment_count}/${item.student_enrollment_count}`,
}));

export const assessmentItemSetFormat = ({
  id,
  label,
  data_collection_parent_id: dataCollectionParentId,
  icon,
  color,
}) => ({
  id: `${id}`,
  label,
  dataCollectionParentId,
  icon,
  color,
});

export const assessmentItemSetsFormat = (items) => items.map((item) => assessmentItemSetFormat(item));

export const fieldTestFormat = ({
  id,
  location_id: locationId,
  location_name_label: locationNameLabel,
  location_unique_id: locationUniqueId,
}) => ({
  id: id || uuidv4(),
  itemId: id,
  locationId,
  locationNameLabel,
  locationUniqueId,
});

export const fieldTestsFormat = (items) => items.map((item) => fieldTestFormat(item));
